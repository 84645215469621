import $ from 'jquery'
$(function() {
    if ($('#payment-form').is('*')) {
        $(document).on('click', '#payment-form-buy-button', function() {
            var $submitButton, data, url, xhr;
            $submitButton = $('[name=submit-button]');
            $submitButton.prop('disabled', true);
            if (!document.forms['payment-form'].checkValidity()) {
                document.forms['payment-form'].reportValidity();
                $submitButton.prop('disabled', false);
                return;
            }
            data = {};
            data.token_api_key = document.getElementById('token_api_key').value;
            if (document.getElementById('card_number')) {
                data.card_number = document.getElementById('card_number').value;
            }
            if (document.getElementById('date_month')) {
                data.card_expire = ('0' + document.getElementById('date_month').value).slice(-2) + "/" + document.getElementById('date_year').value.substr(2, 2);
            }
            if (document.getElementById('card_cvc')) {
                data.security_code = document.getElementById('card_cvc').value;
            }
            data.lang = 'ja';
            url = document.getElementById('token_api_url').value;
            xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            xhr.addEventListener('loadend', function() {
                var response;
                if (xhr.status === 0) {
                    alert("トークンサーバーとの接続に失敗しました");
                    $submitButton.prop('disabled', false);
                    return;
                }
                response = JSON.parse(xhr.response);
                if (xhr.status === 200) {
                    document.getElementById('card_number').value = "";
                    document.getElementById('date_month').value = "";
                    document.getElementById('date_year').value = "";
                    document.getElementById('card_cvc').value = "";
                    document.getElementById('card_token').value = response.token;
                    document.getElementById('mask_card_no').value = response.req_card_number;
                    return document.forms['payment-form'].submit();
                } else {
                    alert(response.message);
                    return $submitButton.prop('disabled', false);
                }
            });
            return xhr.send(JSON.stringify(data));
        });
        return $('input[name="card_id"]:radio').change(function() {
            $('.js-registered-card-submit').removeClass('d-none');
            return $('.js-card-not-selected-btn').addClass('d-none');
        });
    }
});